import { IStock } from 'app/shared/model/stock.model';
import { IOrganisation } from 'app/shared/model/organisation.model';
import { IAction } from 'app/shared/model/action.model';
import { IFormGroup } from 'app/shared/model/form-group.model';

export interface IHerd {
  id?: number;
  groupName?: string;
  hsMember?: boolean;
  notes?: string | null;
  archived?: boolean;
  herdArchived?: boolean;
  stocks?: IStock[] | null;
  farm?: IOrganisation;
  actions?: IAction[] | null;
  formGroups?: IFormGroup[] | null;
  tags?:string |null;
}

export const defaultValue: Readonly<IHerd> = {
  hsMember: false,
  archived: false,
  herdArchived: false,
};

export const joinHerdNames = (herds: IHerd[], tags: string | null) => {
  return herds
    .map(herd => {
      const tagsString = tags ? ` (${tags.replace(/,/g, ', ')})` : '';
      return `${herd.archived ? '[Archived] ' : ''}${herd.groupName}${tagsString}`;
    })
    .join(', ');
}
