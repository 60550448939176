import React from 'react';
import { Step } from "react-joyride";
import { JoyrideImage } from './JoyrideImage';


export const getHealthPlanStatusSteps = (hasHerds: boolean): Step[] => {
  const steps: Step[] = [
    {
      target: 'body',
      placement: 'center',
      disableBeacon: true,
      title: 'Health Plan Status',
      content: (
        <>
          Here is a detailed overview of your livestock groups and any Health Plans associated with them.
        </>
      )
    }

  ]
  if (!hasHerds) {
    steps.push({
      target: '[data-cy="health-plan-status-card-cto-button"]', content: (
        <p>
          Set up your livestock groups to see more information on this page.
        </p>
      )
    });
  } else {
    steps.push(
      {
        target: 'body', placement: 'center',
        content: (<>
          <p>
            To gain an overall Health Plan, each of the livestock groups listed here must have at least one active Production, Infectious Disease, or Focus Area plan.
          </p>
          <p>
            You can mix and match which type of plan you have active across different livestock groups.
          </p>
        </>)
      },
      {
        target: 'body', placement: 'center',
        content: (
          <>
            <p>
              An Infectious Disease plan has additional criteria for it to count towards your overall Health Plan Status.
            </p>
            <p>
              An active Biosecurity Policy must be in place for the livestock group covered by the Infectious Disease plan.
            </p>
          </>
        )
      }
    )
  }
  return steps;
}

export const animalGroupsSteps: Step[] = [
  {
    target: 'body',
    placement: 'center',
    disableBeacon: true,
    title: 'Livestock Groups',
    content: <p>
      Create groups to describe how you manage your stock and make sure the names make sense to you. You will apply actions (e.g. vaccinations) to these groups.
    </p>,
  },
  {
    target: 'body',
    placement: 'center',
    disableBeacon: true,
    title: 'Recording stock numbers',
    content: <p>
      You should record the approximate number of animals you have in each group.
    </p>,
  },
  {
    target: 'body',
    placement: 'center',
    title: 'How many should I add?',
    content: <p>
      Most farms only have one herd or flock for each of the different species they keep (cattle, sheep, goats).
    </p>,
  },
  {
    target: 'body',
    placement: 'center',
    title: 'What about additional herds or flocks?',
    content: (
      <>
        <p>
          Create a separate Herd only if you have a second herd or flock that is completely separately managed (e.g. a separate pedigree herd with a different health status).
        </p>
        <p>
          Having more herds will take more time later.
        </p>
      </>
    ),
  },
]

export const farmPolicySteps: Step[] = [
  {
    target: 'body',
    placement: 'center',
    disableBeacon: true,
    title: 'Farm Policies and Documents',
    content: 'This is where you can manage your farm policies and other documents.',
  },
  {
    target: '[data-cy="select-box-0"]',
    content: <>
      <p>
        You have the freedom to choose which policies you want to use.
      </p>
      <p>
        We&apos;ve set some typical defaults you can use. Select from this dropdown box, or type in your own document title and click &quot;Create&quot;.
      </p>
    </>,
  },
  {
    target: 'body',
    placement: 'center',
    content: <p>
      You can also choose to upload these documents at a later time.
    </p>,
  },
  {
    target: '[data-cy="open-template-0"]',
    content: <p>
      Additionally, for each default policy, we provide a HerdPlan template that you can use.
    </p>
  },
  {
    target: '[data-cy="upload-button-0"]',
    content: <p>
      Download the template to your computer to fill it in, then upload it here.
    </p>
  },
  {
    target: '#form-save-button',
    content: <p>
      After making changes to this form, you&apos;ll need to save your progress before you can upload any documents.
    </p>
  }
]

export const farmStatusSteps: Step[] = [
  {
    target: '[data-cy="audit-monitoring"]',
    disableBeacon: true,
    title: 'Farm Status',
    content: <p>
      {'The Farm Status is broken down across the four core activities (Farm, Team, Health Planning, Veterinary).'}
    </p>
  },
  {
    target: '[data-cy="farm-status-accordion"]',
    content: <>
      <p>{'Each section shows more detail and lets you easily navigate to the relevant activity.'}</p>
      <p>{'Most sections simply have to be completed and updated annually (by default).'}</p>
    </>,
  },
  {
    target: '[data-cy="health-planning-accordion-header"]',
    title: 'Health Planning',
    content: <>
      <p>
        You have more flexibility in Health Planning, as you only need to complete what you need for your farm.
      </p>
      <p>
        You can customise your Health Planning to suit your farm&apos;s specific needs.
      </p>
    </>,
  },
  {
    target: '[data-cy="health-planning-accordion-header"]',
    content: <>
      <JoyrideImage
        imageName='health-plan-status.png'
        alt='Showing the health plan status section.'
      >
        <p>
          For your farm to have an overall Health Plan, ensure each livestock group in your farm has at least one active Production, Infectious Disease, or Focus Area plan.
        </p>
      </JoyrideImage>
    </>,
  },
  {
    target: '[data-cy="health-planning-accordion-header"]',
    content: <>
      <JoyrideImage
        imageName='health-plan-status-infectious-disease.png'
        alt='Showing the health plan status section with Infectious Diseases highlighted.'
      >
        <p>
          An Infectious Disease plan has additional criteria for it to count towards your overall Health Plan Status.
        </p>
        <p>
          An active Biosecurity Policy must be in place for the livestock group covered by the Infectious Disease plan.
        </p>
      </JoyrideImage>
    </>,
  },
]

export const invitingTeamMembersSteps: Step[] = [
  {
    target: 'body',
    placement: 'center',
    disableBeacon: true,
    title: 'Managing your Team',
    content: (<>
      <p>
        Invite other members of your farm on this page.
      </p>
      <p>
        They&apos;ll receive an email inviting them to create an account with HerdPlan, at which point they can accept your invitation.
      </p>
    </>
    ),
  },
  {
    target: 'body',
    placement: 'center',
    content: (
      <p>
        You&apos;re also able to modify your existing team by changing their access level, or removing them from your team if you want.
      </p>
    ),
  },
  {
    target: '[data-cy="team-catch-up-card"]',
    content: (<>
      <p>
        A Team Catch Up should be completed regularly.
      </p>
      <p>
        A Team Catch Up lets you review recent Targets and Action arising from your Health Plan.
      </p>
    </>),
  },
]

export const farmLibrarySteps: Step[] = [
  {
    target: 'body',
    placement: 'center',
    disableBeacon: true,
    title: 'Farm Library',
    content: <p>
      This is the Farm Library, where you can find all the documentation for your farm.
    </p>,
  },
  {
    target: '[data-cy="document-list"]',
    content: <p>
      Find documents by browsing through the categories here.
    </p>,
    spotlightClicks: true,
  },
  {
    target: '[data-cy="document-viewer"]',
    content: <p>
      Use this section to view documents that have been generated, or uploaded in the course of your Health Planning.
    </p>,
  },
  {
    target: '[data-cy="document-viewer"]',
    content: <p>
      You can also upload documents here that you&apos;ve told us about previously.
    </p>,
  },
]

export const formWrapperSteps = (type: 'Production' | 'Focus Area' | 'Infectious Disease'): Step[] => {
  return [
    {
      target: 'body',
      placement: 'center',
      title: `${type} Plan`,
      content: <p>
        This {type} form helps you to set up your Health Plan quickly and easily.
      </p>,
      disableBeacon: true,
    },
    {
      target: '#indicator-root',
      title: `${type} Plan Lifecycle`,
      content: <>
        <p>{type} forms exist in different stages:</p>
        <div className='text-start'>
          <dl>
            <dt>Not Started</dt>
            <dd>This is the start of your {type} form.</dd>
            <dt>Draft</dt>
            <dd>You&apos;ve saved some changes to the form, but it&apos;s not active yet.</dd>
            <dt>Awaiting Approval</dt>
            <dd>The form has been submitted, but it requires approval before it&apos;s active.</dd>
            <dt>Current Plan</dt>
            <dd>The {type} plan is active! It&apos;s now contributing towards the Farm Health Plan status.{type === 'Infectious Disease' && <strong> Don&apos;t forget to have an active Biosecurity Policy in place too.</strong>} </dd>
            <dt>Completed</dt>
            <dd>The {type} plan has run its course, or maybe you decided you didn&apos;t need it any more.</dd>
          </dl>
        </div>
      </>
    },
    {
      target: '[data-cy="breadcrumbs"]',
      title: 'Navigation',
      spotlightClicks: true,
      content: <p>
        Navigate through the different sections of the form using these buttons, or the Next and Previous buttons at the bottom of the page.
      </p>
    },
    {
      target: '[data-cy="overview-breadcrumb"]',
      spotlightClicks: true,
      title: 'Overview',
      content: <>
        <p>Enter details about the plan, such as a title and an end date.</p>
      </>
    },
    {
      target: '[data-cy="targets-breadcrumb"]',
      spotlightClicks: true,
      title: 'Targets',
      content: <JoyrideImage
        imageName='targets-builder.png'
        alt='Showing the Targets Builder'
      >
        <p>Create Targets to track Health Planning goals around your farm.</p>
        <p>Customise our default Targets, or set up your own to meet the needs of your farm.</p>
      </JoyrideImage>,
      styles: {
        options: { width: 800 }
      }
    },
    {
      target: '[data-cy="actions-breadcrumb"]',
      spotlightClicks: true,
      title: 'Actions',
      content: <JoyrideImage
        imageName='actions-builder.png'
        alt='Showing the Actions Builder'
      >
        <p>Create Actions to track tasks around your farm.</p>
        <p>Use our default Actions or set up your own to meet the needs of your farm.</p>
      </JoyrideImage>,
      styles: {
        options: { width: 800 }
      }
    },
    {
      target: '[data-cy="documents-breadcrumb"]',
      spotlightClicks: true,
      title: 'Documents',
      content: <p>Tell us about documents you&apos;ll need to aid you in your {type} plan. Upload these later in the Farm Library.</p>
    },
    {
      target: '[data-cy="form-save-button"]',
      title: 'Save Progress',
      content: <p>Save your progress and come back to this form at any time with the Save button.</p>
    },
    {
      target: '[data-cy="form-submit-button"]',
      content: <>
        <p>Submit the form to progress to the next stage.</p>
        <p>We&apos;ll validate what you&apos;ve entered and let you know if something doesn&apos;t look quite right.</p>
      </>
    },
  ]
}

export const customTemplateSteps: Step[] = [
  {
    target: 'body',
    placement: 'center',
    disableBeacon: true,
    title: 'Custom Templates',
    content: <>
      <p>Create a template to help your clients begin their Health Plans with sensible defaults.</p>
      <p>You can manage which templates your clients see and set them up however you feel best will meet their Health Planning needs.</p>
    </>
  },
  {
    target: '[data-cy="create-custom-template-card-cto-button"]',
    content: <p>Begin here by selecting the HerdPlan template to use as a starting point.</p>
  },
  {
    target: '[data-cy="custom-template-list"]',
    content: <JoyrideImage imageName='unpublished-custom-template.png' alt='A list of created custom templates.'>
      <p>Edit the template to define what should be shown to your client when they begin a new plan.</p>
    </JoyrideImage>,
    styles: { options: { width: 900 }},
  },
  {
    target: '[data-cy="custom-template-list"]',
    content: <JoyrideImage imageName='published-custom-template.png' alt='A list of created custom templates.'>
      <p>Easily publish your template to instantly make it visible to your clients.</p>
    </JoyrideImage>,
    styles: { options: { width: 900 }},
  },
]