import { AccessLevel } from "../enumerations/access-level.model";
import { MembershipStatus } from "../enumerations/membership-status.model";
import { UserRole } from "../enumerations/user-role.model";

export interface IAccess {
  /** the organisation id the access is for */
  orgId?: number;
  /** is this organisation active? */
  orgActive?: boolean;
  /** is the membership to the organisation active? */
  isMembershipActive?: boolean;
  /** the access level of the membership to the organisation */
  accessLevel?: AccessLevel;
  /** the membership status of the membership to the organisation */
  membershipStatus?: MembershipStatus;
  /** the requesting user is a direct member of the organisation, no intermediate organisation between them */
  isDirectMember?: boolean;
  /** the organisation is a farm or coop farm */
  isFarm?: boolean;
  /** the organisatio is a vet practice */
  isVet?: boolean;
  /** the organistion is a consultancy or nutritionists */
  isConsultant?: boolean;
  /** the name of the organisation */
  organisationName?: string;
  /** Set only if the organisation is a farm and the user is _not_ a direct member. Signifies the **role of the user within the organisation which is a member of the farm** */
  childOrganisationRole?: UserRole;
}

export const defaultValue: IAccess = {
  orgId: null,
  orgActive: false,
  isMembershipActive: false,
  accessLevel: null,
  membershipStatus: null,
  isFarm: false,
  isDirectMember: false,
  isVet: false,
  isConsultant: false,
  organisationName: null,
  childOrganisationRole: null,
}

export const parseAccess = (access: IAccess | undefined) => {
  const isOrgActive = access?.orgActive;
  const isActive = access?.isMembershipActive;
  const isConfirmed = access?.membershipStatus === MembershipStatus.CONFIRMED;
  const membershipExists = access !== undefined;
  const allowView = membershipExists && isActive && isConfirmed && isOrgActive;
  const hasWrite = access?.accessLevel === AccessLevel.MANAGER || access?.accessLevel === AccessLevel.READ_WRITE;

  return {
    isOrgActive,
    isActive,
    isConfirmed,
    membershipExists,
    allowView,
    hasWrite,
  };
}
