import { titleCase } from "app/shared/util/stringUtils"

export enum FormType {
  PAFP__ABORTION ='PAFP__ABORTION',

  PAFP__BESPOKE = 'PAFP__BESPOKE',

  PAFP__CLOSTRIDIAL_DISEASE = 'PAFP__CLOSTRIDIAL_DISEASE',

  PAFP__LAMENESS = 'PAFP__LAMENESS',

  PAFP__LAMENESS_SHEEP = 'PAFP__LAMENESS_SHEEP',

  PAFP__NEONATAL_SURVIVAL = 'PAFP__NEONATAL_SURVIVAL',

  PAFP__NUTRITIONAL_GRASS_MANAGEMENT = 'PAFP__NUTRITIONAL_GRASS_MANAGEMENT',

  PAFP__PARASITES = 'PAFP__PARASITES',

  PAFP__PNEUMONIA = 'PAFP__PNEUMONIA',

  PAFP__STILLBIRTH = 'PAFP__STILLBIRTH',

  PAFP__VACCINE_PROGRAMME = "PAFP__VACCINE_PROGRAMME",

  PAFP__YOUNGSTOCK_DISEASE = 'PAFP__YOUNGSTOCK_DISEASE',

  INFECTIOUS_DISEASE = 'INFECTIOUS_DISEASE',

  ANNUAL_REVIEW = 'ANNUAL_REVIEW',

  PRODUCTION_TARGETS = 'PRODUCTION_TARGETS',

  INF_DISEASE__BESPOKE = 'INF_DISEASE__BESPOKE',

  INF_DISEASE__OPA = 'INF_DISEASE__OPA',

  INF_DISEASE__JD = 'INF_DISEASE__JD',

  INF_DISEASE__CLA = 'INF_DISEASE__CLA',

  INF_DISEASE__BD = 'INF_DISEASE__BD',

  INF_DISEASE__MV = 'INF_DISEASE__MV',

  INF_DISEASE__TOXOPLASMOSIS = 'INF_DISEASE__TOXOPLASMOSIS',

  INF_DISEASE__EAE = 'INF_DISEASE__EAE',

  INF_DISEASE__BVD = "INF_DISEASE__BVD",

  INF_DISEASE__NEOSPORA = "INF_DISEASE__NEOSPORA",

  INF_DISEASE__LEPTO = "INF_DISEASE__LEPTO",

  INF_DISEASE__IBR = "INF_DISEASE__IBR",

  INF_DISEASE__ICEBERG_DISEASE = 'INF_DISEASE__ICEBERG_DISEASE',

  INF_DISEASE__BIOSECURITY = 'INF_DISEASE__BIOSECURITY',

  PRODUCTION_PLAN__BESPOKE = 'PRODUCTION_PLAN__BESPOKE',

  PRODUCTION_PLAN__BASIC_CATTLE = 'PRODUCTION_PLAN__BASIC_CATTLE',

  PRODUCTION_PLAN__BASIC_SHEEP = 'PRODUCTION_PLAN__BASIC_SHEEP',

  FARM_POLICIES = 'FARM_POLICIES',

  KPI__BREEDING_CATTLE__SURVIVAL = 'KPI__BREEDING_CATTLE__SURVIVAL',

  KPI__BREEDING_CATTLE__GROWTH = 'KPI__BREEDING_CATTLE__GROWTH',

  KPI__BREEDING_CATTLE__FERTILITY = 'KPI__BREEDING_CATTLE__FERTILITY',


  TEAM_CATCH_UP = "TEAM_CATCH_UP",


  VET_REVIEW_ANTIBIOTIC_USE = "VET_REVIEW_ANTIBIOTIC_USE",

  VET_REVIEW_ANTHELMINTICS = "VET_REVIEW_ANTHELMINTICS",


}

export const friendlyNameForDiseaseType: Partial<Record<FormType, string >> = {
  [FormType.PAFP__ABORTION]: 'Abortion',
  [FormType.PAFP__BESPOKE]: 'Blank Focus Area',
  [FormType.PAFP__CLOSTRIDIAL_DISEASE]: 'Clostridial Disease',
  [FormType.PAFP__LAMENESS]: 'Lameness',
  [FormType.PAFP__LAMENESS_SHEEP]: 'Sheep Lameness',
  [FormType.PAFP__NEONATAL_SURVIVAL]: 'Neonatal Survival',
  [FormType.PAFP__NUTRITIONAL_GRASS_MANAGEMENT]: 'Nutritional Grass Management',
  [FormType.PAFP__PARASITES]: 'Parasites',
  [FormType.PAFP__PNEUMONIA]: 'Cattle Pneumonia',
  [FormType.PAFP__STILLBIRTH]: 'Stillbirth',
  [FormType.PAFP__YOUNGSTOCK_DISEASE]: 'Youngstock Disease',
  [FormType.PAFP__VACCINE_PROGRAMME]: 'Vaccine Programme',
  [FormType.INF_DISEASE__BESPOKE]: 'Blank Infectious Disease',
  [FormType.INF_DISEASE__BVD]: 'BVD (Bovine Viral Diarrhoea)',
  [FormType.INF_DISEASE__NEOSPORA]: 'Neospora Caninum',
  [FormType.INF_DISEASE__LEPTO]: 'Leptospirosis',
  [FormType.INF_DISEASE__IBR]: 'IBR (Infectious Bovine Rhinotracheitis)',
  [FormType.INF_DISEASE__BD]: 'BD (Border Disease)',
  [FormType.INF_DISEASE__CLA]: 'CLA (Caseous Lymphadenitis)',
  [FormType.INF_DISEASE__EAE]: 'EAE (Enzootic Abortion of Ewes)',
  [FormType.INF_DISEASE__JD]: 'JD (Johne\'s Disease)',
  [FormType.INF_DISEASE__MV]: 'MV (Maedi Visna)',
  [FormType.INF_DISEASE__OPA]: 'OPA (Ovine Pulmonary Adenocarcinoma)',
  [FormType.INF_DISEASE__TOXOPLASMOSIS]: 'Toxoplasmosis',
  [FormType.INF_DISEASE__ICEBERG_DISEASE]: 'Iceberg Disease (JD, MV, OPA, CLA)',
  [FormType.INF_DISEASE__BIOSECURITY]: 'Biosecurity',
  [FormType.PRODUCTION_PLAN__BESPOKE]: 'Blank Production plan',
  [FormType.PRODUCTION_PLAN__BASIC_CATTLE]: 'Basic Cattle Production plan',
  [FormType.PRODUCTION_PLAN__BASIC_SHEEP]: 'Basic Sheep Production plan',
  [FormType.FARM_POLICIES]: 'Farm Policies',

}

export const friendlyKpiName: Partial<Record<FormType, string>> = {
  [FormType.KPI__BREEDING_CATTLE__SURVIVAL]: 'Survival',
  [FormType.KPI__BREEDING_CATTLE__GROWTH]: 'Growth',
  [FormType.KPI__BREEDING_CATTLE__FERTILITY]: 'Fertility',
}

export const friendlyVetReviewName: Partial<Record<FormType, string>> = {
  [FormType.VET_REVIEW_ANTIBIOTIC_USE]: 'Antibiotic Use',
  [FormType.VET_REVIEW_ANTHELMINTICS]: 'Anthelmintics',

}

export const friendlyNameForFormType = (input: FormType) => {
  return friendlyNameForDiseaseType[input] ?? friendlyKpiName[input] ?? titleCase(input);
}
