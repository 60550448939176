import React, {useEffect, useImperativeHandle, useState} from "react";
import {FieldValues, UseFormClearErrors, UseFormTrigger} from "react-hook-form";
import {Button, Progress} from "reactstrap";
import { TurnPageRef } from "./formUtils";

interface Props {
  children: React.ReactNode;
  submitBtnText: string;
  submitBtnId: string;
  clearErrors: UseFormClearErrors<FieldValues>
  trigger: UseFormTrigger<FieldValues>
  disableButton ?:boolean
}

// Paginate a ValidatedForm implementing the useForm hook.
// Wraps child form components grouped using fragments or divs -> <> ...<ValidatedField /> </>
// Progress to next stage blocked if:
//   a field in the stage throws an error (validation)
const MultiStageFormWrapper = React.forwardRef<TurnPageRef, Props>(({
                                 submitBtnText,
                                 submitBtnId,
                                 children,
                                 trigger,
                                 clearErrors,
                                 disableButton,
                               }, ref) => {

  const [page, setPage] = useState(0);
  const [initial, setInitial] = useState(0);

  useImperativeHandle(ref,
    () => ({ goToPage: (pageNumber: number) => setPage(pageNumber) }),
    []
  );

  useEffect(() => {
    const timeout = setTimeout(() => setInitial(1), 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    document.addEventListener('keypress', handleEnterKey)
    return () => document.removeEventListener('keypress', handleEnterKey);
  }, []);

  useEffect(() => clearErrors(), [page]);

  const handleEnterKey = (e) => {
    if ((e.keyCode === 10 || e.keyCode === 13) && !onLastPage) {
      e.preventDefault();
      nextPage();
    }
  }

  const total = React.Children.toArray(children).length;
  const onFirstPage = page === 0;
  const onLastPage = page === total - 1;

  const preventNext = async () => {
    return await trigger();
  }

  const nextPage = () => {
    preventNext().then(r => r && setPage(page + 1));
  }

  const prevPage = () => {
    setPage(page - 1);
  }

  return (
    <>
      <header className='d-flex flex-row nowrap gap-3 align-items-center'>
        <Progress striped className='flex-grow-1' value={(page + initial) / total * 100}/>
        <span className='progress-bar-counter'>
          <strong>
            {`${page + 1} / ${total}`}
          </strong>
        </span>
      </header>
      <main className='mt-4 mb-4'>
        {React.Children.map(children, (child, index) => {
          if (index !== page) return null;
          return child;
        })}
      </main>
      <aside className="d-flex gap-3 mb-3">
        <Button
          className='flex-grow-1'
          type='button'
          outline
          onClick={() => prevPage()}
          disabled={onFirstPage}
          style={{opacity: onFirstPage ? 0.25 : 1}}
          data-cy='prev-page'
        >
          Previous
        </Button>
        <Button
          color={onLastPage ? 'secondary' : 'primary'}
          className='flex-grow-1'
          type='button'
          onClick={() => nextPage()}
          outline={onLastPage}
          disabled={onLastPage}
          data-cy='next-page'
          style={{opacity: onLastPage ? 0.25 : 1}}
        >
          Next
        </Button>
      </aside>
      {onLastPage && (
        <footer>
          <Button id={submitBtnId} className='w-100' color='primary' type='submit' disabled={!onLastPage || disableButton} data-cy='submit'>
            {submitBtnText}
          </Button>
        </footer>
      )}
    </>
  );
})

export default MultiStageFormWrapper;
