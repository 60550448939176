import React, { useState, useEffect, useRef } from 'react';
import { DatePicker } from 'reactstrap-date-picker';
import { InputProps } from 'reactstrap';
import { MonthPicker } from './MonthPicker';
import dayjs from 'dayjs';
import { APP_DATE_ONLY_FORMAT } from 'app/config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';

interface Props {
  hideClearButton?: boolean;
  hideValidStyling?: boolean;
}

const DateInput = ({ hideClearButton, hideValidStyling, ...props }: InputProps & Props) => {
  const [value, setValue] = useState<string>((props.value as string) ?? '');
  const [error, setError] = useState<boolean>(false);

  const inputRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.ariaLabel = props.ariaLabel ?? props.label ?? props.name ?? props.id;
    }
  }, [inputRef, props.id]);

  useEffect(() => {
    setValue(props.value as string);
  }, [props.value]);

  useEffect(() => {
    const isValid =
      value === '' || (dayjs(value).isValid() && dayjs(value).isAfter(dayjs('2000-01-01')) && dayjs(value).isBefore(dayjs('3000-01-01')));
    if (!isValid) {
      handleUpdate(null);
    }
    setError(!isValid);
  }, [value]);

  const handleUpdate = (dateValue: string) => {
    const event = {
      target: {
        value: props.type === 'date' ? (dateValue === null ? null : dayjs(dateValue).format(APP_DATE_ONLY_FORMAT)) : dateValue,
        name: props.name,
      },
    } as React.ChangeEvent<HTMLInputElement>;
    props.onChange(event);
  };

  const handleChange = val => {
    setValue(val);
    handleUpdate(val);
  };

  return (
    <DatePicker
      {...props}
      dateFormat="DD/MM/YYYY"
      ariaLabel={{ id: props?.id }}
      weekStartsOn={1}
      closeCalendar="true"
      pickMonthElement={MonthPicker}
      invalid={!props.disabled && (props.invalid || error)}
      valid={!hideValidStyling && !props.disabled && (props.valid || (!!value && !error))}
      maxDetail="month"
      placeholder={props.placeholder ?? 'dd/mm/yyyy'}
      value={value}
      onChange={v => handleChange(v)}
      autoComplete="off"
      clearButtonElement={
        <span>
          <FontAwesomeIcon icon={faXmark} fixedWidth size="sm" />
        </span>
      }
      showClearButton={!hideClearButton}
      showTodayButton
      previousButtonElement={<FontAwesomeIcon icon={faChevronLeft} className="me-1" />}
      nextButtonElement={<FontAwesomeIcon icon={faChevronRight} className="ms-1" />}
      calendarContainer={document.body}
      inputRef={inputRef}
    />
  );
};

export default DateInput;
