import { useAppDispatch, useAppSelector } from "app/config/store";
import { useToggle } from "app/hooks/useToggle";
import React, { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CancelButton } from "../components/CancelButton";
import { LhmInput } from "../layout/form/LhmInput";
import { useLocation } from "react-router-dom";
import { FeedbackDto } from "../model/dto/Feedback.model";
import { sendFeedback } from "../reducers/feedback.reducer";

interface Props {
  linkText: string;
  defaultSubject?: string;
  linkColor?: string;
}

export const Feedback = ({ linkText, defaultSubject, linkColor = 'link' }: Props) => {

  const dispatch = useAppDispatch();
  const location = useLocation();

  const appUser = useAppSelector(state => state.appUser.entity);
  const loading = useAppSelector(state => state.feedback.loading);

  const [isOpen, toggle] = useToggle(false);

  const { handleSubmit, register, formState: { errors, touchedFields }, reset, } = useForm<FieldValues>({ mode: 'onTouched', defaultValues: { subject: defaultSubject ?? '', message: '' } });

  useEffect(() => {
    if (isOpen) return;
    reset();
  }, [isOpen]);

  const handleFormSubmit = () => {
    handleSubmit(handleValidSubmit)();
  }

  const handleValidSubmit = ({ subject, message }) => {
    const dto: FeedbackDto = {
      appUser,
      subject,
      message,
      appProperties: {
        route: location.pathname,
      }
    }
    dispatch(sendFeedback(dto)).then(r => r.meta.requestStatus === 'fulfilled' && toggle());
  }

  return (
    <>
      <Button color={linkColor} onClick={toggle} type='button'>
        {linkText ?? 'Send Feedback'}
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} backdrop="static" keyboard={false}>
        <ModalHeader toggle={toggle}>
          Send Feedback to HerdPlan
        </ModalHeader>

        <ModalBody>
          <LhmInput
            name='subject'
            label='Subject'
            register={register}
            error={errors.subject}
            isTouched={touchedFields.subject}
            validate={{
              required: 'Subject is required.'
            }}
          />

          <LhmInput
            name='message'
            label='Message'
            type='textarea'
            register={register}
            error={errors.message}
            isTouched={touchedFields.message}
            validate={{
              required: 'Message is required.'
            }}
          />

        </ModalBody>

        <ModalFooter>
          <CancelButton onClick={toggle} />
          <div className='flex-grow-1' />
          <Button
            type='button'
            color='dark'
            onClick={handleFormSubmit}
            disabled={loading}
          >
            Send
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
