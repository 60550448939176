import React from "react";

interface Props {
  imageName: string;
  alt?: string;
  children?: React.ReactNode
}

export const JoyrideImage = ({ imageName, alt, children }: Props) => {

  return (
    <div>
      <img
        style={{ width: '100%', borderRadius: '1em' }}
        className='mb-2 p-2'
        src={`content/images/helptext/${imageName}`}
        alt={alt}
      />
      {children}
    </div>
  );
}
