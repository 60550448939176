import auditEvent from 'app/entities/audit-event/audit-event.reducer';
import organisation from 'app/entities/organisation/organisation.reducer';
import appUser from 'app/entities/app-user/app-user.reducer';
import farmDetails from 'app/entities/farm-details/farm-details.reducer';
import membership from 'app/entities/membership/membership.reducer';
import event from 'app/entities/event/event.reducer';
import notification from 'app/entities/event/notification.reducer';
import farm from 'app/entities/organisation/farm.reducer';
import vetPractice from 'app/entities/vet-practice/vet-practice.reducer';
import herd from 'app/entities/herd/herd.reducer';
import stock from 'app/entities/stock/stock.reducer';
import animalGroup from 'app/entities/herd/animal-group.reducer';
import form from 'app/entities/form/form.reducer';
import action from 'app/entities/action/action.reducer';
import template from 'app/entities/template/template.reducer';
import jsonSchema from 'app/entities/json-schema/json-schema.reducer';
import workflowStep from 'app/entities/workflow-step/workflow-step.reducer';
import formData from 'app/entities/form-data/form-data.reducer';
import approval from 'app/entities/approval/approval.reducer';
import document from 'app/entities/document/document.reducer';
import defaultActions from 'app/entities/default-actions/default-actions.reducer';
import formGroup from 'app/entities/form-group/form-group.reducer';
import consultancy from 'app/entities/consultancy/consultancy.reducer';
import calendar from 'app/modules/my-calendar/calendar.reducer';
import competency from 'app/entities/competency/competency.reducer';
import target from 'app/entities/target/target.reducer';
import customTemplate from 'app/entities/custom-template/custom-template.reducer';
import preferences from 'app/entities/preferences/preferences.reducer';
import dataSheet from 'app/entities/data-sheet/data-sheet.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  auditEvent,
  organisation,
  farm,
  appUser,
  farmDetails,
  membership,
  event,
  notification,
  vetPractice,
  herd,
  stock,
  animalGroup,
  form,
  action,
  template,
  jsonSchema,
  workflowStep,
  formData,
  approval,
  document,
  defaultActions,
  formGroup,
  consultancy,
  calendar,
  competency,
  target,
  customTemplate,
  preferences,
  dataSheet,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
