import React, { useState } from 'react';
import { Button, Collapse, Nav } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faCow,
  faGears,
  faHeartPulse,
  faHouse,
  faBriefcaseMedical,
  faPeopleGroup,
  faXmark,
  faBookBookmark,
  faRankingStar,
  faHouseMedicalCircleCheck,
  faVialVirus,
  faCalendarCheck,
  faFileContract,
  faMagnifyingGlassChart,
  faAngleLeft,
} from '@fortawesome/free-solid-svg-icons';
import { faAddressBook, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { Brand, renderDevRibbon, RenderSuperViewRibbon } from 'app/shared/layout/navigation/sidebar/SidebarComponents';
import './sidebar.scss';
import classNames from 'classnames';
import SidebarAdminMenu from 'app/shared/layout/menus/sidebar-admin';
import { SidebarButton, SidebarItem, SidebarSubMenu, SidebarSubMenuItem } from '../../menus/sidebar-menu-components';
import { SIDEBAR_WIDTH } from 'app/config/constants';
import { useFarmAccess } from 'app/hooks/useFarmAccess';
import { HelpTextJoyride } from './help-text/HelpTextJoyride';
import { useIsAdmin } from 'app/hooks/useIsAdmin';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { toggleSuperView } from 'app/shared/reducers/app-config.reducer';
import { useHistory } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  isMobile: boolean;
  toggle: () => void;
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
}

export const Sidebar = ({ isOpen, isMobile, toggle, isInProduction, isAdmin, isAuthenticated, isOpenAPIEnabled, }: Props) => {
  const [, isSuperView] = useIsAdmin();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { IS_ACTIVE, IS_FARMER, IS_VET, IS_CONSULTANT } = useFarmAccess();
  
  const farmId = useAppSelector(state => state.farm.entity?.id);

  const prefix = IS_FARMER ? 'My' : 'Client';

  const [run, setRun] = useState(false);
  const [helpTextAvailable, setHelpTextAvailable] = useState(false);

  const exitSuperView = () => {
    history.replace(farmId ? `/admin/admin-tasks/hierarchy/farms/${farmId}` : '/');
    dispatch(toggleSuperView(false));
  }

  const showAdminSidebar = isAdmin && !isSuperView;
  const showFarmItems = (isAdmin && isSuperView) || ((IS_FARMER || IS_ACTIVE) && !isAdmin);

  return (
    <div data-cy="sidebar">
      {renderDevRibbon(isInProduction)}
      <RenderSuperViewRibbon />
      <Collapse horizontal isOpen={isOpen} className={'sidebar-wrapper shadow'} navbar>
        <div
          className={classNames('sidebar-container d-flex flex-column', { 'sidebar-full': isMobile && isOpen })}
          style={{ maxWidth: isMobile && isOpen ? 'calc(100vw - 2px)' : `${SIDEBAR_WIDTH}px` }}
        >
          <div className={classNames('sidebar-header shadow w-100 d-flex flex-row ps-3', { 'sidebar-header-fixed': isMobile && isOpen })}>
            <Brand />
            {isMobile && (
              <Button size="lg" className="float-right border-left" onClick={toggle}>
                <FontAwesomeIcon icon={faXmark} fixedWidth size="lg" />
              </Button>
            )}
          </div>
          <div className="sidebar-contents overflow-auto d-flex flex-column flex-grow-1">
            <Nav navbar>
              {isSuperView && (
                <div className='border-bottom'>
                  <SidebarButton
                    text='Exit Super View'
                    icon={faAngleLeft}
                    handleClick={exitSuperView}
                    active={false}
                    datacy='exit-super-view'
                    pulse
                  />
                </div>
              )}
              {showAdminSidebar && <SidebarAdminMenu showOpenAPI={isOpenAPIEnabled} showDatabase={!isInProduction} />}
              {!isAdmin && dashboard(IS_VET, IS_CONSULTANT)}
              {IS_VET && vetItems()}
              {IS_CONSULTANT && consultantItems()}

              {showFarmItems && farmItems(prefix)}
            </Nav>
            <div className="flex-grow-1"></div>
            {!isSuperView && (
              <Nav navbar className="border-top pb-2">
                <SidebarButton
                  handleClick={() => setRun(true)}
                  text="Help"
                  icon={faQuestionCircle}
                  active={run}
                  pulse={helpTextAvailable}
                  datacy="sb-help"
                />
                <HelpTextJoyride run={run} endRun={() => setRun(false)} setAvailable={setHelpTextAvailable}  />
                <SidebarItem to={'/account/settings'} text={'Settings'} icon={faGears} datacy="settings" />
              </Nav>
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

const dashboard = (isVet: boolean, isConsultant: boolean) => {
  const dashboardText = isVet || isConsultant ? 'My Clients' : 'Dashboard';
  const dashboardIcon = isVet || isConsultant ? faAddressBook : faHouse;
  return <SidebarItem datacy="sb-dashboard" to="/" text={`${dashboardText}`} icon={dashboardIcon} />;
};

const vetItems = () => {
  return (
    <>
      <SidebarItem datacy="sb-my-practice" to={'/my-practice'} text={'My Practice Team'} icon={faBriefcaseMedical} />
      <SidebarItem to="/my-templates" text="My Templates" icon={faFileContract} datacy="sb-my-templates" />
    </>
  );
};

const consultantItems = () => {
  return (
    <>
      <SidebarItem to="/my-consultancy" text="My Consultancy Team" icon={faBriefcaseMedical} datacy="sb-my-consultancy" />
    </>
  );
};

const farmItems = (prefix: 'My' | 'Client') => {
  return (
    <>
      <SidebarSubMenu datacy='sb-my-farm' path={'/my-farm'} text={`${prefix} Farm`} icon={faCow} highlightChildren excludeChildren={['data-sheets']}>
        <SidebarSubMenuItem datacy='sb-data-sheets' to={'/my-farm/data-sheets'} text='Data Sheets' icon={faMagnifyingGlassChart} />
      </SidebarSubMenu>
      <SidebarItem datacy="sb-my-team" to="/my-team" text={`${prefix} Team`} icon={faPeopleGroup} />
      <SidebarSubMenu datacy="sb-health-plan" text={`${prefix} Health Plan`} icon={faHeartPulse} path="/my-health-plan">
        <SidebarSubMenuItem datacy="sb-production-plan" to={'/my-health-plan/production'} text={'Production'} icon={faCalendarCheck} />
        <SidebarSubMenuItem
          datacy="sb-infectious-diseases"
          to={'/my-health-plan/infectious-diseases'}
          text={'Infectious Diseases'}
          icon={faVialVirus}
        />
        <SidebarSubMenuItem datacy="sb-focus-areas" to={'/my-health-plan/focus-areas'} text={'Focus Areas'} icon={faRankingStar} />
      </SidebarSubMenu>
      <SidebarItem data-cy="sb-farm-library" to="/farm-library" text="Farm Library" icon={faBookBookmark} />
      <SidebarItem datacy="sb-my-calendar" to="/my-calendar" text="My Calendar" icon={faCalendarDays} />
      <SidebarItem datacy="sb-vet-review" to={'/review/vet-area'} text={'Vet Area'} icon={faHouseMedicalCircleCheck} />
    </>
  );
};
