import React from 'react';
import {NavbarBrand} from "reactstrap";
import {NavLink as Link} from "react-router-dom";
import { useIsAdmin } from 'app/hooks/useIsAdmin';

export const renderDevRibbon = (isInProduction: boolean) =>
  isInProduction === false ? (
    <div className="ribbon dev">
      <span>Development</span>
    </div>
  ) : null;

export const BrandIcon = props => {
  return <img src='content/images/logo/HerdPlan_Logo_Icon.svg' alt='HerdPlan logo' style={{width: '46px', height: '46px'}} />
}

export const Brand = props => {
  return (
    <NavbarBrand tag={Link} to="/" className="brand-logo flex-grow-1 justify-content-center align-items-center">
      <BrandIcon />
      <img src='content/images/logo/HerdPlan_Logo_Typographic.svg' alt='HerdPlan' style={{height: '30px'}} />
    </NavbarBrand>
  );
}

export const RenderSuperViewRibbon = () => {
  const [isAdmin, isSuperView] = useIsAdmin();
  if (!(isAdmin && isSuperView)) return null;
  return (
    <div className="ribbon" id='admin-view-ribbon'>
      <span>Admin View</span>
    </div>
  );
}
