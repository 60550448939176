import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { MembershipDtoOption, MembershipsDto } from './hierarchy/domain';
import { IOrganisation } from 'app/shared/model/organisation.model';
import { OrganisationType } from 'app/shared/model/enumerations/organisation-type.model';
import { IAppUser } from 'app/shared/model/app-user.model';
import { NewVetPracticeDto } from './add-vet-practices/domain';

export interface AdminTasksState {
  hierarchy: {
    dto: MembershipsDto;
    loading: boolean;
  }
  organisations: {
    vetPractices: IOrganisation[];
    vetPracticesLoading: boolean;
    consultancies: IOrganisation[];
    consultanciesLoading: boolean;
    farms: IOrganisation[];
    farmsLoading: boolean;
  }
  users: {
    entities: IAppUser[];
    loading: boolean;
  }
  createVetPractice: {
    loading: boolean;
    createdPractice: IOrganisation;
  }
}

const initialState: AdminTasksState = {
  hierarchy: {
    dto: null,
    loading: false,
  },
  organisations: {
    vetPractices: [],
    vetPracticesLoading: false,
    consultancies: [],
    consultanciesLoading: false,
    farms: [],
    farmsLoading: false,
  },
  users: {
    entities: [],
    loading: false,
  },
  createVetPractice: {
    loading: false,
    createdPractice: null,
  }
}

const apiUrl = 'api/admin-tasks';

export const getAppUsers = createAsyncThunk(
  'admin-tasks/getAppUsers',
  async () => {
    return axios.get<IAppUser[]>(`${apiUrl}/users`);
  },
  { serializeError: serializeAxiosError, }
);

export const getFarmOrganisations = createAsyncThunk(
  'admin-tasks/getFarmOrganisations',
  async () => {
    const requestUrl = `${apiUrl}/organisations/${[OrganisationType.FARM, OrganisationType.COOP_FARM]}`;
    return axios.get<IOrganisation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError, }
);

export const getVetOrganisations = createAsyncThunk(
  'admin-tasks/getVetOrganisations',
  async () => {
    const requestUrl = `${apiUrl}/organisations/${[OrganisationType.VET_PRACTICE]}`;
    return axios.get<IOrganisation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError, }
);

export const getConsultantOrganisations = createAsyncThunk(
  'admin-tasks/getConsultantOrganisations',
  async () => {
    const requestUrl = `${apiUrl}/organisations/${[OrganisationType.CONSULTANCY, OrganisationType.NUTRITION_PRACTICE]}`;
    return axios.get<IOrganisation[]>(requestUrl);
  },
  { serializeError: serializeAxiosError, }
);

export const getHierarchyFor = createAsyncThunk(
  'admin-tasks/getHierarchy',
  async ({ id, option }: { id: number, option: MembershipDtoOption }) => {
    const requestUrl = `${apiUrl}/hierarchy/${option}/${id}`;
    return axios.get<MembershipsDto>(requestUrl);
  },
  { serializeError: serializeAxiosError, }
);

export const createVetPractice = createAsyncThunk(
  'admin-tasks/createVetPractice',
  async ({ dto }: { dto: NewVetPracticeDto }) => {
    const requestUrl = `${apiUrl}/create-vet-practice`;
    return axios.post<IOrganisation>(requestUrl, dto);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const AdminTasksSlice = createSlice({
  name: 'admin-tasks',
  initialState,
  reducers: {
    reset() { // resets everything
      return initialState;
    },
    resetCreateVetPractice(state) {
      return {
        ...state,
        createVetPractice: {
          loading: false,
          createdPractice: null,
        }
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAppUsers.fulfilled, (state, action) => {
        state.users.entities = action.payload.data;
        state.users.loading = false;
      })
      .addCase(getAppUsers.pending, (state) => {
        state.users.loading = true;
      })
      .addCase(getVetOrganisations.fulfilled, (state, action) => {
        state.organisations.vetPractices = action.payload.data;
        state.organisations.vetPracticesLoading = false;
      })
      .addCase(getVetOrganisations.pending, (state) => {
        state.organisations.vetPracticesLoading = true;
      })
      .addCase(getFarmOrganisations.fulfilled, (state, action) => {
        state.organisations.farms = action.payload.data;
        state.organisations.farmsLoading = false;
      })
      .addCase(getFarmOrganisations.pending, (state) => {
        state.organisations.farmsLoading = true;
      })
      .addCase(getConsultantOrganisations.fulfilled, (state, action) => {
        state.organisations.consultancies = action.payload.data;
        state.organisations.consultanciesLoading = false;
      })
      .addCase(getConsultantOrganisations.pending, (state) => {
        state.organisations.consultanciesLoading = true;
      })
      .addCase(getHierarchyFor.fulfilled, (state, { payload: { data } }) => {
        state.hierarchy.dto = data;
        state.hierarchy.loading = false;
        // update the downloaded object into the corresponding item array
        if (data.appUser) {
          const index = state.users.entities.findIndex(e => e.id === data.appUser.id);
          if (index > -1) {
            state.users.entities[index] = data.appUser;
          } else {
            state.users.entities.push(data.appUser);
          }
        } else if (data.organisation) {
          const type = data.organisation.organisationType;
          let key: keyof typeof state.organisations = null;
          if (type === OrganisationType.VET_PRACTICE) {
            key = 'vetPractices';
          } else if (type === OrganisationType.FARM || type === OrganisationType.COOP_FARM) {
            key = 'farms';
          } else if (type === OrganisationType.CONSULTANCY || type === OrganisationType.NUTRITION_PRACTICE) {
            key = 'consultancies';
          }
          const index = key === null ? -1 : (state.organisations[key] as IOrganisation[]).findIndex(v => v.id === data.organisation.id);
          if (index > -1 && key !== null) {
            state.organisations[key][index] = data.organisation;
          } else if (key !== null && index < 0) {
            (state.organisations[key] as IOrganisation[]).push(data.organisation);
          }
        }
      })
      .addCase(getHierarchyFor.pending, (state) => {
        state.hierarchy.loading = true;
      })
      .addCase(createVetPractice.pending, (state) => {
        state.createVetPractice = { loading: true, createdPractice: null, }
      })
      .addCase(createVetPractice.fulfilled, (state, { payload: { data } }) => {
        state.createVetPractice = { loading: false, createdPractice: data }
      })
      .addCase(createVetPractice.rejected, (state) => {
        state.createVetPractice = initialState.createVetPractice;
      })
  },
});

export const { reset, resetCreateVetPractice } = AdminTasksSlice.actions;

export default AdminTasksSlice.reducer;