export interface IVetPractice {
  id?: number;
  name?: string;
  address1?: string;
  address2?: string | null;
  area?: string | null;
  town?: string;
  county?: string;
  postcode?: string;
  tel?: string;
  email?: string;
  hidden?: boolean | null;
}

export const defaultValue: Readonly<IVetPractice> = {
  hidden: false,
};
