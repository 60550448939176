import React, { useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { useHistory } from 'react-router-dom';
import { animalGroupsSteps, customTemplateSteps, farmLibrarySteps, farmPolicySteps, farmStatusSteps, formWrapperSteps, getHealthPlanStatusSteps, invitingTeamMembersSteps } from './JoyrideSteps';
import { useAppSelector } from 'app/config/store';
import { useFarmAccess } from 'app/hooks/useFarmAccess';


interface Props {
  run: boolean;
  endRun: () => void;
  setAvailable: (pulse: boolean) => void;
}
export const HelpTextJoyride = ({ run, endRun, setAvailable }: Props) => {

  const history = useHistory();
  const herds = useAppSelector(state => state.herd.entities);
  const {IS_VET} = useFarmAccess();

  const steps = (() => {
    switch (history.location.pathname.replace(/\d+/g, '*')) {
      case '/':
        return farmStatusSteps;
      case '/my-team':
        return invitingTeamMembersSteps;
      case '/my-farm/animal-groups':
        return animalGroupsSteps;
      case '/my-farm/policies/form/*':
        return farmPolicySteps;
      case '/my-health-plan':
        return getHealthPlanStatusSteps(herds.length > 0);
      case '/farm-library':
        return farmLibrarySteps;
      case '/my-health-plan/production/form/*':
        return formWrapperSteps('Production');
      case '/my-health-plan/infectious-diseases/form/*':
        return formWrapperSteps('Infectious Disease');
      case '/my-health-plan/focus-areas/form/*':
        return formWrapperSteps('Focus Area');
      case '/my-templates':
        if (!IS_VET) return [];
        return customTemplateSteps;
      default:
        return [];
    }
  })();

  useEffect(() => {
    if (steps.length === 0) {
      endRun();
    }
  }, [run, steps]);

  useEffect(() => {
    setAvailable(steps.length > 0);
  }, [steps]);

  const handleCallback = ({ status }) => {
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      endRun();
    }
  }

  return (
    <Joyride
      run={run}
      steps={steps}
      continuous={true}
      callback={handleCallback}
      showSkipButton
      hideCloseButton
      disableOverlayClose
      styles={{
        options: {
          primaryColor: '#00a19e',
          width: 600,
          zIndex: 9999999,
        }
      }}
      spotlightPadding={10}
      locale={{ last: 'Finish' }}
    />
  );
}
